<template>
	<div class="article-box">
		<el-col class="col" :span="8">
			<p class="pc">

				<span @click="$router.go(-1)" style="color: #409EFF;cursor: pointer;">设备批次管理</span> /
				<span>校温管理</span>
			</p>
			<el-button class="btn" type="text" @click="levelChange('创建校温')">创建校温</el-button>
			<!-- 弹窗 -->
			<el-dialog class="roll-dialog" :title="addtype" :visible.sync="dialogFormVisible" top="50px"
				:close-on-click-modal="false">
				
				<!-- <div class="hide"></div> -->
				
				<el-form :model="levelAdd" ref="articleForm" style="width:460px;margin:0 auto">

					<el-form-item label="展示温度:" style="display: flex;">
						<el-select placeholder="" v-model="levelAdd.show_val" style="width: 150px; float: left" :min="0"
							:max="100" :controls="false">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value"></el-option>

						</el-select>
						<!-- <el-select-number v-model="num" :precision="1" :step="0.1" :max="42"></el-select-number> -->
						<span>℃</span>

					</el-form-item>
					<template v-for="(item,index) in levelAdd.ask">
						<span v-if="index!==0"  class="el-icon-close" style="float: right;" @click="removeItem(index)"></span>
						<el-form-item label="返回温度:" style="display: flex;">
							<el-select placeholder="" v-model="levelAdd.ask[index].back_val"
								style="width: 150px; float: left" :min="0" :max="100" :controls="false">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
							<span>℃</span>
						</el-form-item>
						<el-form-item label="环境温度:" style="display: flex;">
							<el-select placeholder="" @change="listenChange" v-model="levelAdd.ask[index].vibe_start"
								style="width: 80px; " :min="0" :max="100" :controls="false">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
							<span>℃</span>
							<span>-</span>
							<el-select placeholder="" v-model="levelAdd.ask[index].vibe_end" style="width: 80px; "
								:min="0" :max="100" :controls="false">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
							<span>℃</span>
						</el-form-item>
						<div style="border:1px solid #CCC"></div>
						<!-- 	<el-form-item label="实测温度:">
							<el-select v-model="levelAdd.ask[index].vibe_end" style="width: 260px; float: left" :min="0"
								:max="100" :controls="false"></el-select>
						</el-form-item> -->
					</template>
					<div>

						<el-button @click="addAsk">添加</el-button>
					</div>
					<el-form-item style="margin-top:5px">
						<el-button @click="dialogFormVisible = false">取消</el-button>
						<el-button type="primary" @click="levelAdds">
							<span>添加</span>

						</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</el-col>
		<el-table :data="machineData" style="width: 100%" v-loading="loading">
			<!-- <el-table-column prop="serial" label="序号" type="index" align="center"></el-table-column> -->
			<el-table-column prop="id" label="设备ID" align="center" width="300">

			</el-table-column>
			<el-table-column prop="show_val" label="展示温度" align="center" width="500">

			</el-table-column>
			<!-- <el-table-column prop="MechineCode" label="校对温度" align="center" width="150"></el-table-column> -->


			<el-table-column label="校对温度" align="center" width="300" show-overflow-tooltip>
				<template v-for="item in askList">
					<span>返回温度: {{item.back_val}}℃ 环境温度：{{item.vibe_start}}℃ 实测温度：{{ item.vibe_end }}℃</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<el-button class="btn" type="text" @click="levelChange('校温管理',scope.row)">管理</el-button>
					<el-button class="btn" type="text" @click="deletes(scope.row)">删除</el-button>
					<!-- <el-button type="text" @click="dialogVisible = true">删除</el-button> -->

				</template>

				<!-- 弹窗 -->
				<!-- 	<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" >
						<span>确定要删除这条数据</span>
						<span slot="footer" class="dialog-footer" >
							<el-button @click="dialogVisible = false">取 消</el-button>
							<el-button class="btn" type="text" @click="deletes(scope.row)">确定</el-button>
						</span>
					</el-dialog> -->

			</el-table-column>
			<!-- <span>返回温度: {{item.back_val}} 环境温度：{{item.vibe_start}} 实测温度：{{ item.vibe_end }}</span> -->
			<!-- <el-table-column prop="provinceName" label="省" align="center"></el-table-column>
      <el-table-column prop="cityName" label="市" align="center"></el-table-column>
      <el-table-column prop="areaName" label="区" align="center"></el-table-column> -->
		</el-table>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
				layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	// 32.3 - 42

	import {

		revisionList,
		addRevision, //校温创建
		editRevision, //校温修改
		revisionDel, //校温删除

	} from '@/api/adminApi.js'
	export default {
		data() {
			return {
				editRow: null,
				num: 32.3,
				batch_id: this.$route.params.batch_id,
				machineData: [], // 设备列表
				dialogVisible: false,
				loading: false, //加载框
				currpage: 1, // 初始页
				pagesize: 10, // 每页显示条目个数
				CountNum: 0, // 总条数
				dialogFormVisible: false,
				askList: [],
				flag: 0,
				levelAdd: {
					// id: '',
					// batch_id: '',
					// show_val: '',
					// ask: [{
					// 	// back_val: '',
					// 	// vibe_start: '',
					// 	// vibe_end: '',
					// }]
				}, // 添加修改 
				addtype: '', //判断新增 修改

				options: [{
					value: '32.3',
					label: '32.3'
				}, {
					value: '32.4',
					label: '32.4'
				}, {
					value: '32.5',
					label: '32.5'
				}, {
					value: '32.6',
					label: '32.6'
				}, {
					value: '32.7',
					label: '32.7'
				}, {
					value: '32.8',
					label: '32.8'
				}, {
					value: '32.9',
					label: '32.9'
				}, {
					value: '33',
					label: '33'
				}, {
					value: '33.1',
					label: '33.1'
				}, {
					value: '33.2',
					label: '33.2'
				}, {
					value: '33.3',
					label: '33.3'
				}, {
					value: '33.4',
					label: '33.4'
				}, {
					value: '33.5',
					label: '33.5'
				}, {
					value: '33.6',
					label: '33.6'
				}, {
					value: '33.7',
					label: '33.7'
				}, {
					value: '33.8',
					label: '33.8'
				}, {
					value: '33.9',
					label: '33.9'
				}, {
					value: '34',
					label: '34'
				}, {
					value: '34.1',
					label: '34.1'
				}, {
					value: '34.2',
					label: '34.2'
				}, {
					value: '34.3',
					label: '34.3'
				}, {
					value: '34.4',
					label: '34.4'
				}, {
					value: '34.5',
					label: '34.5'
				}, {
					value: '34.6',
					label: '34.6'
				}, {
					value: '34.7',
					label: '34.7'
				}, {
					value: '34.8',
					label: '34.8'
				}, {
					value: '34.9',
					label: '34.9'
				}, {
					value: '35',
					label: '35'
				}, {
					value: '35.1',
					label: '35.1'
				}, {
					value: '35.2',
					label: '35.2'
				}, {
					value: '35.3',
					label: '35.3'
				}, {
					value: '35.4',
					label: '35.4'
				}, {
					value: '35.5',
					label: '35.5'
				}, {
					value: '35.6',
					label: '35.6'
				}, {
					value: '35.7',
					label: '35.7'
				}, {
					value: '35.8',
					label: '35.8'
				}, {
					value: '35.9',
					label: '35.9'
				}, {
					value: '36',
					label: '36'
				}, {
					value: '36.1',
					label: '36.1'
				}, {
					value: '36.2',
					label: '36.2'
				}, {
					value: '36.3',
					label: '36.3'
				}, {
					value: '36.4',
					label: '36.4'
				}, {
					value: '36.5',
					label: '36.5'
				}, {
					value: '36.7',
					label: '36.7'
				}, {
					value: '36.8',
					label: '36.8'
				}, {
					value: '36.9',
					label: '36.9'
				}, {
					value: '37',
					label: '37'
				}, {
					value: '37.1',
					label: '37.1'
				}, {
					value: '37.2',
					label: '37.2'
				}, {
					value: '37.3',
					label: '37.3'
				}, {
					value: '37.4',
					label: '37.4'
				}, {
					value: '37.5',
					label: '37.5'
				}, {
					value: '37.6',
					label: '37.6'
				}, {
					value: '37.7',
					label: '37.7'
				}, {
					value: '37.8',
					label: '37.8'
				}, {
					value: '37.9',
					label: '37.9'
				}, {
					value: '38',
					label: '38'
				}, {
					value: '38.1',
					label: '38.1'
				}, {
					value: '38.2',
					label: '38.2'
				}, {
					value: '38.3',
					label: '38.3'
				}, {
					value: '38.4',
					label: '38.4'
				}, {
					value: '38.5',
					label: '38.5'
				}, {
					value: '38.6',
					label: '38.6'
				}, {
					value: '38.7',
					label: '38.7'
				}, {
					value: '38.8',
					label: '38.8'
				}, {
					value: '38.9',
					label: '38.9'
				}, {
					value: '39',
					label: '39'
				}, {
					value: '39.1',
					label: '39.1'
				}, {
					value: '39.2',
					label: '39.2'
				}, {
					value: '39.3',
					label: '39.3'
				}, {
					value: '39.4',
					label: '39.4'
				}, {
					value: '39.5',
					label: '39.5'
				}, {
					value: '39.6',
					label: '39.6'
				}, {
					value: '39.7',
					label: '39.7'
				}, {
					value: '39.8',
					label: '39.8'
				}, {
					value: '39.9',
					label: '39.9'
				}, {
					value: '40',
					label: '40'
				}, {
					value: '40.1',
					label: '40.1'
				}, {
					value: '40.2',
					label: '40.2'
				}, {
					value: '40.3',
					label: '40.3'
				}, {
					value: '40.4',
					label: '40.4'
				}, {
					value: '40.5',
					label: '40.5'
				}, {
					value: '40.6',
					label: '40.6'
				}, {
					value: '40.7',
					label: '40.7'
				}, {
					value: '40.8',
					label: '40.8'
				}, {
					value: '40.9',
					label: '40.9'
				}, {
					value: '41',
					label: '41'
				}, {
					value: '41.2',
					label: '41.2'
				}, {
					value: '41.3',
					label: '41.3'
				}, {
					value: '41.4',
					label: '41.4'
				}, {
					value: '41.5',
					label: '41.5'
				}, {
					value: '41.6',
					label: '41.6'
				}, {
					value: '41.7',
					label: '41.7'
				}, {
					value: '41.8',
					label: '41.8'
				}, {
					value: '41.9',
					label: '41.9'
				}, {
					value: '42',
					label: '42'
				}, ]

			}
		},
		mounted() {
			this.machineSerchList(1) // 获取第一页设备列表

		},
		methods: {
			listenChange() {
				console.log(this.levelAdd.ask)
			},
			addAsk() {
				this.levelAdd.ask.push({
					back_val: '',
					vibe_start: '',
					vibe_end: '',
					flag:true
				})
			},
			removeItem(val) {
				this.levelAdd.ask.splice(val, 1);
			},
			deletes(row) {
				const data = {
					id: row.id,
				}
				console.log(row)
				this.$confirm("是否确定删除选中的数据?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
					distinguishCancelAndClose: true,
					closeOnClickModal: false
				}).then(() => {
					revisionDel(data).then(res => {

						this.machineSerchList(this.currpage)

					}).catch((err) => {
						console.log(err)
					})
				}).catch(() => {

				})



			},


			machineSerchList(currpage) { // 设备列表
				this.loading = true
				const data = {
					batch_id: this.batch_id,
					page: currpage, // 当前页数
					limit: this.pagesize // 每页条数
				}
				revisionList(data).then(res => {

					this.machineData = res.data.list // 设备列表
					res.data.list.map(item => {
						this.askList = item.ask
					})
					this.CountNum = parseInt(res.data.countNum) // 总条数
					console.log(parseInt(res.data.countNum))
					this.loading = false
					console.log(res.data.list)
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(val) { // 分页
				this.machineSerchList(val)
				this.currpage = val
			},
			//创建校温
			levelChange(type, obj) { // 点击按钮
				this.addtype = type
				if (type == '校温管理') {

					this.levelAdd = obj
					console.log(obj)
				} else {
					this.levelAdd = {
						id: '',
						batch_id: '',
						show_val: '',
						ask: [{
							back_val: '',
							vibe_start: '',
							vibe_end: '',
						}]
					}
				}

				this.dialogFormVisible = true;
			},
			levelAdds() { // 点击按钮
				if (this.addtype == '创建校温') {

					const data = {
						batch_id: this.batch_id,
						show_val: this.levelAdd.show_val,
						ask: JSON.stringify(this.levelAdd.ask)


					}
					console.log(JSON.stringify([this.levelAdd.ask]))
					//创建接口
					addRevision(data).then(res => {
						if (res.status.code == 1) {

							this.$message.success('操作成功');
							this.machineSerchList(this.currpage) // 获取列表

							this.dialogFormVisible = false;
						} else {
							this.$message.error(res.status.msg);
						}
					}).catch(err => {
						console.log(err)
					})


				} else {

					const data = {
						id: this.levelAdd.id,
						show_val: this.levelAdd.show_val,
						ask: JSON.stringify(this.levelAdd.ask)

					}
					editRevision(data).then(res => {
						if (res.status.code == 1) {
							this.$message.success('操作成功');
							this.machineSerchList(this.currpage) // 获取列表
							this.dialogFormVisible = false;
						} else {
							this.$message.error(res.status.msg);
						}
					}).catch(err => {
						console.log(err)
					})
				}

			},


		}
	}
</script>

<style lang="scss" scoped>
	.article-box {
		.col {

			width: 100%;
			display: flex;
			justify-content: space-between;

			.btn {
				width: 10.0625rem;
				height: 3.375rem;
				background-color: #003882;
				font-size: 1.25rem;
				color: #ffffff;

			}

		}

	}

	.block {

		margin-top: 0.3125rem;

		margin-bottom: 0.3125rem;

	}

	.hide {
		position: absolute;
		right: 3.125rem;
		top: 6.8125rem;
		width: 15.25rem;
		height: 3.125rem;
		background-color: #ffffff;
	
	}

	.roll-dialog ::v-deep .el-dialog .el-dialog__body {
		padding: 3px 30px;
		overflow-y: scroll;
		height: 400px;
	}
</style>
